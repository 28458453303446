import React from "react";
// import styles from "/src/components/MovieSearch.module.css";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}

const SearchIcon: React.FC<IconProps> = ({ className, ...props }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1805_472)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66658 11.0188C3.65328 11.6781 4.81331 12.03 6 12.03C7.33821 12.03 8.63033 11.5829 9.67669 10.7715L14.6698 15.8098C14.739 15.8799 14.8216 15.9355 14.9126 15.9732C15.0036 16.011 15.1012 16.0302 15.1998 16.0298C15.302 16.0239 15.4019 15.9968 15.4931 15.9504C15.5843 15.9039 15.6649 15.839 15.7298 15.7598C15.8702 15.6191 15.9491 15.4285 15.9491 15.2298C15.9491 15.031 15.8702 14.8404 15.7298 14.6998L10.7394 9.70942C11.5522 8.66258 12 7.36938 12 6.03C12 4.84331 11.6481 3.68327 10.9888 2.69658C10.3295 1.70989 9.39246 0.94085 8.2961 0.486724C7.19975 0.0325985 5.99335 -0.0862215 4.82946 0.14529C3.66558 0.376801 2.59648 0.948246 1.75736 1.78736C0.918247 2.62648 0.346802 3.69557 0.115291 4.85946C-0.11622 6.02335 0.00259972 7.22975 0.456726 8.3261C0.910851 9.42246 1.67989 10.3595 2.66658 11.0188ZM3.51944 2.31678C4.2542 1.82791 5.11748 1.56802 6 1.57C7.18113 1.57265 8.31299 2.04371 9.14724 2.87984C9.98149 3.71596 10.45 4.84887 10.45 6.03C10.45 6.91253 10.1882 7.77522 9.69766 8.50888C9.20714 9.24254 8.50999 9.81418 7.69445 10.1515C6.87891 10.4887 5.98164 10.5765 5.11622 10.4036C4.25079 10.2307 3.45611 9.80489 2.83277 9.18015C2.20943 8.5554 1.78544 7.75977 1.61448 6.89396C1.44353 6.02815 1.53328 5.13108 1.87238 4.31629C2.21148 3.50151 2.78468 2.80565 3.51944 2.31678Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1805_472">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.0299988)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SearchIcon;
